import React from "react";
import {
  Box,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
  VStack
} from "@chakra-ui/react";
import { VFlex, VFlexCC } from './bits/UtilityTags.js';
import AppFooter from "./navs/AppFooter";

export default function PgTermsOfUse({ ...props }) {
  return (
    <VFlexCC id="home" pt={40}>
      <VFlex w={['100%', '92%', '89%', '940px']} gap={4} p={2}>
        <Heading as="h1" size="lg">TERMS OF USE</Heading>
        <Text>Last updated June 13, 2024</Text>

        <Text>
          Welcome to Cubecert! These Terms of Use ("Terms") govern your use of our application ("App"), services, and website ("Services"). By accessing or using Cubecert, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Services.
        </Text>

        <Heading as="h2" size="md">1. Acceptance of Terms</Heading>
        <Text>By using the Cubecert App, you agree to comply with and be legally bound by these Terms. If you do not agree to these Terms, you must not use our Services.</Text>

        <Heading as="h2" size="md">2. Modifications to Terms</Heading>
        <Text>
          Cubecert reserves the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our website. You are advised to review these Terms periodically for any changes. Your continued use of the Services after the posting of the revised Terms means that you accept and agree to the changes.
        </Text>

        <Heading as="h2" size="md">3. Use of the Services</Heading>
        <Heading as="h3" size="sm">Eligibility</Heading>
        <Text>You must be at least 18 years old to use our Services. By using our Services, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into these Terms.</Text>

        <Heading as="h3" size="sm">Account Registration</Heading>
        <Text>
          To access certain features of the App, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
        </Text>

        <Heading as="h3" size="sm">Prohibited Activities</Heading>
        <Text>You agree not to engage in any of the following prohibited activities:</Text>
        <UnorderedList pl={4}>
          <ListItem>Copying, distributing, or disclosing any part of the Services in any medium.</ListItem>
          <ListItem>Using any automated system, including "robots," "spiders," or "offline readers," to access the Services.</ListItem>
          <ListItem>Attempting to interfere with, compromise with, or disrupt the integrity or performance of the Services or the data contained therein.</ListItem>
          <ListItem>Impersonating any person or entity, or falsely stating or otherwise misrepresenting your affiliation with a person or entity.</ListItem>
          <ListItem>Using the Services for any illegal or unauthorized purpose or engaging in, encouraging, or promoting any activity that violates these Terms.</ListItem>
        </UnorderedList>

        <Heading as="h2" size="md">4. Intellectual Property Rights</Heading>
        <Text>
          All content, trademarks, service marks, trade names, logos, and intellectual property related to Cubecert are the property of Cubecert or its licensors. You are granted a limited, non-exclusive, non-transferable license to access and use the Services for your personal, non-commercial use. You may not copy, modify, distribute, sell, or lease any part of our Services or software without our prior written consent.
        </Text>

        <Heading as="h2" size="md">5. Privacy Policy</Heading>
        <Text>
          Your use of the Services is also governed by our Privacy Policy, which can be found at <Link href="https://www.cubecert.com/privacy" isExternal>www.cubecert.com/privacy</Link>. By using the Services, you consent to the practices described in the Privacy Policy.
        </Text>

        <Heading as="h2" size="md">6. Disclaimers</Heading>
        <Text>
          Cubecert provides the Services on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, about the operation or availability of the Services, or the information, content, materials, or products included on the Services. To the fullest extent permissible by applicable law, we disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.
        </Text>

        <Heading as="h2" size="md">7. Limitation of Liability</Heading>
        <Text>
          To the fullest extent permitted by applicable law, Cubecert and its affiliates, officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Services; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Services by any third party; or (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Services.
        </Text>

        <Heading as="h2" size="md">8. Indemnification</Heading>
        <Text>
          You agree to indemnify, defend, and hold harmless Cubecert, its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Services or your violation of these Terms.
        </Text>

        <Heading as="h2" size="md">9. Governing Law</Heading>
        <Text>
          These Terms shall be governed by and construed in accordance with the laws of the State of Florida, without regard to its conflict of law principles. You agree to submit to the personal jurisdiction of the state and federal courts located in New York County, New York, for the purpose of litigating all such claims or disputes.
        </Text>

        <Heading as="h2" size="md">10. Termination</Heading>
        <Text>
          Cubecert may terminate or suspend your account and access to the Services immediately, without prior notice or liability, if you breach these Terms. Upon termination, your right to use the Services will immediately cease.
        </Text>

        <Heading as="h2" size="md">11. Miscellaneous</Heading>
        <Heading as="h3" size="sm">Entire Agreement</Heading>
        <Text>
          These Terms, along with our Privacy Policy, constitute the entire agreement between you and Cubecert regarding the use of the Services and supersede any prior agreements between you and Cubecert relating to your use of the Services.
        </Text>

        <Heading as="h3" size="sm">Severability</Heading>
        <Text>
          If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.
        </Text>

        <Heading as="h3" size="sm">Waiver</Heading>
        <Text>
          The failure of Cubecert to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision.
        </Text>

        <Heading as="h2" size="md">12. Contact Information</Heading>
        <Text>
          If you have any questions about these Terms, please contact us at:&nbsp;
          <Link href="mailto:support@cubecert.com">support@cubecert.com</Link>
        </Text>

        <Heading as="h2" size="md">13. Two Factor Authentication</Heading>
        <Text>
          When enrolling into the Two Factor Authentication (2FA) SMS program, you will receive a message indicating
          'Your Cubecert verification code is 123456. Enter this code in the application to verify your phone number. Reply STOP to opt-out.'
          You can cancel the SMS service at any time. Just text “STOP” to the short code.
          After you send the SMS message “STOP” to us, we will send you an SMS message to confirm that you have been unsubscribed.
          After this, you will no longer receive SMS messages from us.
          If you want to join again, access your profile settings to sign up and we will start sending SMS messages to you again.
          If you are experiencing issues with the messaging program you can reply with the keyword HELP for more assistance,
          or you can get help directly at&nbsp;<Link href="mailto:support@cubecert.com">support@cubecert.com</Link>.
          Carriers are not liable for delayed or undelivered messages.
          As always, message and data rates may apply for any messages sent to you from us and to us from you.
          Message frequency varies and will be sent during account login.
          If you have any questions about your text plan or data plan, it is best to contact your wireless provider.
          If you have any questions regarding privacy, please read our privacy policy:&nbsp;
          <Link href="https://cubecert.com/privacy" isExternal>https://cubecert.com/privacy</Link>.
        </Text>

        <Text>
          By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use.
        </Text>
      </VFlex>
      <VFlex mt={40} w={['97%', '92%', '89%', '940px']} gap={3}>
        <AppFooter />
      </VFlex>

    </VFlexCC>
  );
}
